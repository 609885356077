<template>
  <v-app>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="10" class="mx-auto text-center">
          <h1>FIND A PROVIDER</h1>
          <p>
            We only collaborate with providers who are accredited by the
            PLASCHEMA. They are highly experienced and produce excellent
            outcomes for their consumers.
          </p>
          <v-row>
            <v-col cols="12" class="mx-auto">
              <v-form @submit.prevent="searchProviders">
                <v-row justify="center">
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      v-model="state"
                      :rules="stateFieldRules"
                      label="- Select State -"
                      :items="stateList"
                      color="green darken-4"
                      required
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      v-model="lga"
                      :rules="lgaFieldRules"
                      label="- Select LGA -"
                      :items="lgaList"
                      color="green darken-4"
                      required
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      medium
                      type="submit"
                      color="green darken-4"
                      class="w-100 white--text font-weight-bold text-capitalize"
                    >
                      Search
                      <v-icon class="ml-2">mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="searchResults" class="mb-12">
        <v-col cols="10" class="mx-auto text-left">
          <v-data-table
            :headers="tableHeaders"
            :items="tableContents"
            :items-per-page="5"
            class="elevation-1"
            height="300px"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import states from "@/assets/json/states.json";
import lgas from "@/assets/json/lgas.json";

export default {
  name: "care-provider",
  data() {
    return {
      valid: false,
      state: "",
      lga: "",
      stateFieldRules: [
        (v) => !!v || "Please select a State from the dropdown",
      ],
      lgaFieldRules: [(v) => !!v || "Please select an LGA from the dropdown"],
      providers: null,
    };
  },
  methods: {
    ...mapActions({
      findCareProvider: "claimModule/fetchHealthFacility",
    }),
    async searchProviders() {
      if (this.lga.length > 0) {
        const { lga } = this;
        const response = await this.findCareProvider({ lga });
        if (response["status"] === 200) {
          this.providers = response.data;
          return;
        }
      }
    },
  },
  computed: {
    searchResults() {
      return this.providers;
    },
    tableHeaders() {
      return [
        {
          text: "NAME",
          value: "name",
        },
        {
          text: "LGA",
          value: "lga",
        },
      ];
    },
    tableContents() {
      return this.providers.map((provider) => ({
        name: provider.name,
        lga: provider.lga,
      }));
    },
    stateList() {
      return states;
    },
    lgaList() {
      return lgas[this.state] || [];
    },
  },
};
</script>
